import { css } from 'styled-components';

export const breakpoints = {
	values: {
		xs: 320,
		sm: 640,
		md: 962,
		lg: 1024,
		xl: 1280
	},
	heights: {
		xs: 660,
		sm: 750,
		md: 850,
		lg: 950,
		xl: 1050,
		xxl: 1300
	}
};

const getMin = key => `@media screen and (min-width: ${breakpoints.values[key]}px)`;
const getMax = key => `@media screen and (max-width: ${breakpoints.values[key] - 1}px)`;

const getMinHeight = (key, isDesktop, isExtraLarge) =>
	`@media screen and (min-height: ${breakpoints.heights[key]}px) ${
		isDesktop
			? ` and (min-width: ${isExtraLarge ? breakpoints.values.xl : breakpoints.values.lg}px)`
			: ''
	}`;
const getMaxHeight = (key, isDesktop, isExtraLarge) =>
	`@media screen and (max-height: ${breakpoints.heights[key] - 1}px) ${
		isDesktop
			? ` and (min-width: ${isExtraLarge ? breakpoints.values.xl : breakpoints.values.lg}px)`
			: ''
	}`;

export const devices = {
	xsMobile: getMax('xs'),
	mobile: getMax('sm'),
	tablet: getMax('md'),
	tabletLg: getMax('lg'),
	onlyDesktop: getMin('lg'),
	desktopSm: getMax('xl'),
	desktopLg: getMin('xl')
};

export const heights = {
	xsHeight: getMaxHeight('xs'),
	smHeight: getMaxHeight('sm'),
	mdHeight: getMaxHeight('md'),
	lgHeight: getMaxHeight('lg'),
	lgHeightWidth: getMaxHeight('lg', true, true),
	xlHeight: getMaxHeight('xl'),
	tabletHeight: getMaxHeight('xxl'),
	lgTabletHeight: getMinHeight('xxl'),
	desktopSm: getMaxHeight('sm', true),
	desktopMd: getMaxHeight('md', true),
	desktopLg: getMinHeight('lg', true)
};

export const mediaBreaks = {
	xsMobile: (...args) => css`
		${devices.xsMobile} {
			${css(...args)}
		}
	`,
	mobile: (...args) => css`
		${devices.mobile} {
			${css(...args)}
		}
	`,
	tablet: (...args) => css`
		${devices.tablet} {
			${css(...args)}
		}
	`,
	tabletLg: (...args) => css`
		${devices.tabletLg} {
			${css(...args)}
		}
	`,
	onlyDesktop: (...args) => css`
		${devices.onlyDesktop} {
			${css(...args)}
		}
	`,
	desktopSm: (...args) => css`
		${devices.desktopSm} {
			${css(...args)}
		}
	`,
	desktopLg: (...args) => css`
		${devices.desktopLg} {
			${css(...args)}
		}
	`,
	onlyPrint: (...args) => css`
		@media print {
			${css(...args)}
		}
	`,
	maxHeight: (...args) => css`
		${devices.maxHeight} {
			${css(...args)}
		}
	`,
	xsHeight: (...args) => css`
		${heights.xsHeight} {
			${css(...args)}
		}
	`,
	smHeight: (...args) => css`
		${heights.smHeight} {
			${css(...args)}
		}
	`,
	mdHeight: (...args) => css`
		${heights.mdHeight} {
			${css(...args)}
		}
	`,
	lgHeight: (...args) => css`
		${heights.lgHeight} {
			${css(...args)}
		}
	`,
	lgHeightWidth: (...args) => css`
		${heights.lgHeightWidth} {
			${css(...args)}
		}
	`,
	xlHeight: (...args) => css`
		${heights.xlHeight} {
			${css(...args)}
		}
	`,
	tabletHeight: (...args) => css`
		${heights.tabletHeight} {
			${css(...args)}
		}
	`,
	lgTabletHeight: (...args) => css`
		${heights.lgTabletHeight} {
			${css(...args)}
		}
	`,
	desktopSmHeight: (...args) => css`
		${heights.desktopSm} {
			${css(...args)}
		}
	`,
	desktopMdHeight: (...args) => css`
		${heights.desktopMd} {
			${css(...args)}
		}
	`,
	desktopLgHeight: (...args) => css`
		${heights.desktopLg} {
			${css(...args)}
		}
	`
};

export const iOS = () =>
	['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
		navigator.platform
	) ||
	// iPad on iOS 13 detection
	(navigator.userAgent.includes('Mac') && 'ontouchend' in document);
