import { useCallback, useEffect, useState } from 'react';
import { isEmptyParam, callRequest } from 'utils/request';
import mapTargetFieldData from './utils/mapTargetFieldData';
import mapDataMapping from './utils/mapDataMapping';
import prepareApiParameters from './utils/prepareApiParameters';
import calculateKeyPath from './utils/calculateKeyPath';

const useAsyncData = ({
	source,
	endpointParameters = [],
	initialData = {},
	currentData,
	fieldsArrayChildrenPrefix,
	fieldsArrayChildrenUnique,
	dataMapping,
	targetField
}) => {
	const [status, setStatus] = useState({ isLoading: true, hasError: false });
	const [updatedData, setUpdatedData] = useState({});

	const processResponseData = useCallback(
		response => {
			const responseObject = Array.isArray(response) ? response[0] : response;

			return {
				...initialData,
				...mapTargetFieldData({
					response,
					targetField,
					fieldsArrayChildrenPrefix,
					fieldsArrayChildrenUnique
				}),
				...mapDataMapping({
					responseObject,
					dataMapping,
					fieldsArrayChildrenPrefix,
					fieldsArrayChildrenUnique
				})
			};
		},
		[initialData, fieldsArrayChildrenPrefix, fieldsArrayChildrenUnique, dataMapping, targetField]
	);

	const fetchData = useCallback(
		async () => {
			const { pathParameters, filters } = prepareApiParameters({
				endpointParameters,
				currentData
			});

			if (isEmptyParam(pathParameters) || isEmptyParam(filters))
				return setStatus({ isLoading: false, hasError: false });

			try {
				const response = await callRequest(source, filters, pathParameters);
				const newData = processResponseData(response);
				setUpdatedData(newData);
				setStatus({ isLoading: false, hasError: false });
			} catch {
				setStatus({ isLoading: false, hasError: true });
			}
		},
		[source, endpointParameters, currentData, processResponseData]
	);

	useEffect(
		() => {
			fetchData();
		},
		[fetchData]
	);

	return { ...status, updatedData, calculateKeyPath };
};

export default useAsyncData;
