import { getPathFiltersParameters, getQueryFiltersParameters } from 'utils/request';

/**
 * Prepares the API parameters for a request by extracting path parameters and filters.
 * @param {Object} params The parameters for the preparation function.
 * @param {Array} params.endpointParameters The configuration for API endpoint parameters.
 *                                         Should include filters and path parameters.
 * @param {Object} [params.currentData={}] The current data to be used for parameter extraction.
 * @returns {{ pathParameters: Object, filters: Object }} An object containing the path parameters and filters.
 */
const prepareApiParameters = ({ endpointParameters = [], currentData = {} }) => {
	const pathParameters = getPathFiltersParameters(endpointParameters, currentData);
	const filters = getQueryFiltersParameters(endpointParameters, currentData, true);
	return { pathParameters, filters };
};

export default prepareApiParameters;
