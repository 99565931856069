import { get, set } from 'lodash';
import calculateKeyPath from './calculateKeyPath';

/**
 * Maps response object data to a structured object based on a data mapping configuration.
 * @param {Object} params The parameters for the mapping function.
 * @param {Object} params.responseObject The API response object to map. Should be a key-value pair object.
 * @param {Object<string, string>} [params.dataMapping={}] A mapping configuration object, where keys represent source paths
 *                                                and values represent target keys.
 * @param {string} [params.fieldsArrayChildrenPrefix=''] Optional prefix for the field keys.
 * @param {boolean} [params.fieldsArrayChildrenUnique=false] Whether the field keys should be unique.
 * @returns {Object} The mapped object with data transformed according to the mapping configuration.
 */
const mapDataMapping = ({
	responseObject,
	dataMapping = {},
	fieldsArrayChildrenPrefix = '',
	fieldsArrayChildrenUnique = false
}) =>
	Object.keys(dataMapping).reduce((acc, mappingKey) => {
		const key = dataMapping[mappingKey];
		const keyPath = calculateKeyPath({
			targetField: key,
			fieldsArrayChildrenPrefix,
			fieldsArrayChildrenUnique
		});
		const value = get(responseObject, mappingKey);

		return set(acc, keyPath, value);
	}, {});

export default mapDataMapping;
