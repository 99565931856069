import {
	getDate,
	getMonth,
	getYear,
	getDay,
	getDaysInMonth,
	startOfToday,
	previousMonday,
	isMonday,
	nextSunday,
	isSunday,
	isSameYear,
	eachDayOfInterval,
	subDays,
	addDays,
	setMonth,
	isEqual,
	startOfDay,
	parseISO,
	format,
	differenceInCalendarDays,
	isBefore,
	endOfDay,
	isAfter,
	isSameMonth,
	lastDayOfMonth,
	startOfMonth,
	nextMonday,
	isValid
} from 'date-fns';
import { translateHelperString } from 'utils/translate';
import viewsFormat from './index';

/**
 * @function fillWithZero
 * @description returns a string with a zero if the number is less than 10
 * Otherwise returns the same provided number
 * @param {number} number - A number
 * @returns {string} - A string with the zero added to the number according to validation
 * @example
 * fillWithZero(5);
 * result: '05'
 */
const fillWithZero = number =>
	number && typeof number === 'number' ? String(number).padStart(2, '0') : '';

/**
 * @function parseRange
 * @description returns a days range in IsoString data.
 * @param {DateType} start - Current Date to get start date
 * @param {DateType} end - Current Date to get end date
 * @returns {object} - Formatted object with start and end isoStrings
 * @example
 * parseRange('Mon May 27 2024 00:00:00 GMT-0300 (hora estándar de Argentina)',
 * 				'Sun Jun 02 2024 00:00:00 GMT-0300 (hora estándar de Argentina)');
 * result: { start: '2024-06-03T03:00:00.000Z', end: '2024-06-09T03:00:00.000Z' }
 */
const parseRange = (start, end) => {
	const startDate = start;
	const endDate = endOfDay(end);
	const startISO = startDate.toISOString();
	const endISO = endDate.toISOString();

	return {
		start: startISO,
		end: endISO
	};
};

/**
 * @function getLocaleString
 * @description returns a date formatted as string according to the locale.
 * @param {DateType} date - Current Date to get start date year data
 * @param {time} string - calendar type
 * @param {type} string - how to format data
 * @returns {string} - Formatted date string
 * @example
 * const currentDate = startOfToday();
 * const day = getLocaleString(currentDate, 'weekday', 'long');
 * result: 'Monday'
 */

export const getLocaleString = (date, time, type) =>
	date.toLocaleDateString('en-US', { [time]: type });

/**
 * @function getDays
 * @description creates an interval based on start and end params
 * @param {DateType} start - start date to get an interval
 * @param {DateType} end - end date to get an interval
 * @returns {Array} - days in an interval
 * @example
 * const date1 = DateType;
 * const date2 = DateType
 * getDays(date1, date2);
 * result: [Mon Feb 26 2024 00:00:00 GMT-0300 (hora estÃ¡ndar de Argentina),
 * Tue Feb 27 2024 00:00:00 GMT-0300 (hora estÃ¡ndar de Argentina), ...]
 */

export const getDays = (start, end) =>
	eachDayOfInterval({
		start,
		end
	});

/**
 * @function getMonday
 * @description gets last monday form a date
 * @param {DateType} date - date to get monday
 * @returns {DateType} - next sunday date
 * @example
 * const date = DateType;
 * getMonday(date);
 * result: {
		"date": "2024-03-04T03:00:00.000Z",
		"dayNumber": 4,
		"day": "Monday",
		"dayNumberInWeek": 2,
		"dayShort": "Mon",
		"year": 2024,
		"month": "March",
		"monthShort": "Mar",
		"monthNumber": 3,
		"monthIndex": 2
	}
 */

export const getMonday = date => {
	if (isMonday(date)) return date;
	return previousMonday(date);
};

/**
 * @function getSunday
 * @description gets next sunday form a date
 * @param {DateType} date - date to get sunday
 * @returns {DateType} - next sunday date
 * @example
 * const date = DateType;
 * getSunday(date);
 * result: {
		"date": "2024-03-10T03:00:00.000Z",
		"dayNumber": 10,
		"day": "Sunday",
		"dayNumberInWeek": 1,
		"dayShort": "Sun",
		"year": 2024,
		"month": "March",
		"monthShort": "Mar",
		"monthNumber": 3,
		"monthIndex": 2
	}
 */

export const getSunday = date => {
	if (isSunday(date)) return date;
	return nextSunday(date);
};

/**
 * @function createDate
 * @description formats data from a date
 * @param {DateType} date - date to get data
 * @returns {object} - Object with formated data for a date
 * @example
 * const date = DateType;
 * createDate(date);
 * result: {
		"date": "2024-03-04T03:00:00.000Z",
		"dayNumber": 4,
		"day": "Monday",
		"dayNumberInWeek": 2,
		"dayShort": "Mon",
		"year": 2024,
		"month": "March",
		"monthShort": "Mar",
		"monthNumber": 3,
		"monthIndex": 2
	}
 */

export const createDate = date => {
	const currentDate = date ?? startOfToday();
	const dayNumber = getDate(currentDate);
	const dayNumberInWeek = getDay(currentDate) + 1;
	const year = getYear(currentDate);
	const monthNumber = getMonth(currentDate) + 1;
	const monthIndex = getMonth(currentDate);
	const monthShort = getLocaleString(currentDate, 'month', 'short');
	const day = getLocaleString(currentDate, 'weekday', 'long');
	const dayShort = getLocaleString(currentDate, 'weekday', 'short');
	const month = getLocaleString(currentDate, 'month', 'long');

	return {
		date: currentDate,
		dayNumber,
		day,
		dayNumberInWeek,
		dayShort,
		year,
		month: translateHelperString(`fieldValue.month.${month}`),
		monthShort,
		monthNumber,
		monthIndex
	};
};

/**
 * @function getYearBetweenDates
 * @description gets start and end year to render data
 * @param {DateType} start - Current Date to get start date year data
 * @param {DateType} end - Current Date to get end date year data
 * @returns {object} - Object with 2 keys with start and end year
 * @example
 * const date1 = DateType;
 * const date2 = DateType;
 * getYearBetweenDates(date1, date2);
 * result: {
		"startYear": "",
		"endYear": 2024
	}
 */

const getYearBetweenDates = (start, end) => {
	if (isSameYear(start.date, end.date)) return { startYear: '', endYear: start.year };
	return { startYear: start.year, endYear: end.year };
};

/**
 * @function getDisplayedMonth
 * @description creates a string with from and to date for make the calendar header info
 * @param {DateType} date - Current Date to get data
 * @returns {string} - Formated text with the current week info
 * @example
 * const date = DateType;
 * getDisplayedMonth(date);
 * result: '4 Mar - 10 Mar 2024'
 */

const getDisplayedMonth = (startDate, endDate) => {
	const weekStart = createDate(startDate);
	const weekEnd = createDate(endDate);

	const { startYear, endYear } = getYearBetweenDates(weekStart, weekEnd);

	return `${fillWithZero(weekStart.dayNumber)} ${weekStart.month.slice(
		0,
		3
	)} ${startYear} - ${fillWithZero(weekEnd.dayNumber)} ${weekEnd.month.slice(0, 3)} ${endYear}`;
};

/**
 * @function getDataFromDay
 * @description formats data from a date
 * @param {DateType} date - Current Date to get data
 * @returns {object} - Object with data for the date
 * @example
 * const date = DateType;
 * getDataFromDay(date);
 * result: {
		"year": 2024,
		"monthIndex": 2,
		"dayNumber": 10,
		"dayNumberInWeek": 1,
		"date": "2024-03-10T03:00:00.000Z",
		"monthShort": "Mar"
	}
 */

export const getDataFromDay = date => {
	const dayMonthIndex = getMonth(date);
	const dayNumberInWeek = getDay(date) + 1;
	const yearNumber = getYear(date);
	const monthShort = getLocaleString(date, 'month', 'short');
	const dayName = getLocaleString(date, 'weekday', 'long');
	const yearDayNumber = getDate(date);

	return {
		year: yearNumber,
		monthIndex: dayMonthIndex,
		dayNumber: yearDayNumber,
		dayNumberInWeek,
		dayName,
		date,
		monthShort
	};
};

/**
 * @function getActualMonth
 * @description With the month index and the current year, returns an array with
 * all days to render of that month in a given year.
 * @param {number} month - month index
 * @param {number} year - current year
 * @returns {Array} - Array with days to render in the monthly calendar from monday to sunday
 * @example
 * const month = 2;
 * const year = 2024;
 * getMonthActual(month, year);
 * result: [Mon Feb 26 2024 00:00:00 GMT-0300 (hora estÃ¡ndar de Argentina),
 * Tue Feb 27 2024 00:00:00 GMT-0300 (hora estÃ¡ndar de Argentina),
 * Wed Feb 28 2024 00:00:00 GMT-0300 (hora estÃ¡ndar de Argentina),
 * Thu Feb 29 2024 00:00:00 GMT-0300 (hora estÃ¡ndar de Argentina),
 * Fri Mar 01 2024 00:00:00 GMT-0300 (hora estÃ¡ndar de Argentina), ...]
 */

const getMonthActual = (month = getMonth(startOfToday()), year = getYear(startOfToday())) => {
	const daysInMonth = getDaysInMonth(new Date(year, month));
	const startDay = getMonday(new Date(year, month, 1));
	const endDay = getSunday(new Date(year, month, daysInMonth));

	const monthDays = getDays(startDay, endDay);

	return monthDays;
};

/**
 * @function createWeek
 * @description Returns selected week from a date
 * @param {DateType} actualDate - Current Date to create the currentWeek
 * @returns {object} - Object with week info
 * @example
 * const date = DateType;
 * createWeek(date);
 * result: {
    "date": "2024-03-04T03:00:00.000Z",
    "dayNumber": 4,
    "displayedMonth": "4 Mar - 10 Mar 2024",
    "monthIndex": 2,
    "year": 2024,
    "calendarDaysOfWeek": [
			{
				"year": 2024,
				"monthIndex": 2,
				"dayNumber": 4,
				"dayNumberInWeek": 2,
				"date": "2024-03-04T03:00:00.000Z",
				"monthShort": "Mar"
			}, ...],
	"weekDaysNames": [
		{
			"day": "Monday",
			"dayShort": "Mon"
		},...],
	"start": '2024-06-03T03:00:00.000Z',
	"end": '2024-06-09T03:00:00.000Z'
	}
 */

export const createWeek = actualDate => {
	const date = actualDate ?? startOfToday();

	const firstDay = getMonday(date);
	const lastDay = getSunday(date);

	const currentDate = createDate(firstDay);

	const { year, monthIndex, dayNumber } = currentDate;
	const startDate = getMonday(date);
	const endDate = getSunday(startDate);
	const displayedMonth = getDisplayedMonth(startDate, endDate);

	const weekDays = getDays(firstDay, lastDay);

	const weekDaysNames = weekDays.map(day => ({
		day: getLocaleString(day, 'weekday', 'long'),
		dayShort: getLocaleString(day, 'weekday', 'short')
	}));

	const calendarDaysOfWeek = weekDays.map(day => getDataFromDay(day));
	const parsedRange = parseRange(
		calendarDaysOfWeek[0]?.date,
		calendarDaysOfWeek[calendarDaysOfWeek.length - 1]?.date
	);

	return {
		date: currentDate.date,
		dayNumber,
		displayedMonth,
		monthIndex,
		year,
		calendarDaysOfWeek,
		weekDaysNames,
		start: parsedRange.start,
		end: parsedRange.end
	};
};

/**
 * @function createMonth
 * @description Returns selected month from a date
 * @param {DateType} currentDate - Current Date to create the currentMonth
 * @returns {object} - Object with month info
 * @example
 * const date = DateType;
 * createMonth(date);
 * result: {
		"monthName": "March",
		"monthIndex": 2,
		"monthNumber": 3,
		"year": 2024,
		"start": '2024-06-03T03:00:00.000Z',
		"end": '2024-06-09T03:00:00.000Z'
	}
 */

export const createMonth = currentDate => {
	const date = currentDate ?? startOfToday();

	const { month: monthName, year, monthNumber, monthIndex } = createDate(date);
	const firstDay = startOfMonth(date);
	const lastDay = lastDayOfMonth(date);

	const parsedRange = parseRange(firstDay, lastDay);

	return {
		monthName: translateHelperString(`fieldValue.month.${monthName.toLowerCase()}`),
		monthIndex,
		monthNumber,
		year,
		firstDay,
		lastDay,
		start: parsedRange.start,
		end: parsedRange.end
	};
};

/**
 * @function getCalendarDaysOfMonth
 * @description Returns an array with the days to render in a calndar from monday to sunday
 * @param {number} year - Current year
 * @param {number} monthIndex - Current month index
 * @returns {Array} - Array of currentMonth days
 * @example
 * const year = 2024;
 * const monthIndex = 1;
 * getCalendarDaysOfMonth(year, monthIndex);
 * result: [{
		"year": 2024,
		"monthIndex": 1,
		"dayNumber": 26,
		"dayNumberInWeek": 2,
		"date": "2024-02-26T03:00:00.000Z",
		"monthShort": "Feb"
	}, {
		"year": 2024,
		"monthIndex": 1,
		"dayNumber": 27,
		"dayNumberInWeek": 3,
		"date": "2024-02-27T03:00:00.000Z",
		"monthShort": "Feb"
	} ...]
 */

export const getCalendarDaysOfMonth = ({ year, monthIndex }) => {
	const currentMonthCalendar = getMonthActual(monthIndex, year);

	const calendarDaysOfMonth = currentMonthCalendar.map(day => getDataFromDay(day));

	return calendarDaysOfMonth;
};

/**
 * @function getDateByDirection
 * @description Returns date based on a button if its today, next or prev
 * @param {DateType} date - Selected date
 * @param {string} direction - today, next or prev
 * @param {boolean} isWeekly - CalendarMode
 * @param {object} selectedMonth - Selected month
 * @returns {DateType} - New selected date
 * @example
 * const date = DateType;
 * const direction = 'prev';
 * const isWeekly = true;
 * const selectedMonth = {monthObject};
 * getDateByDirection(date, direction, isWeekly, selectedMonth);
 * result: Tue Feb 27 2024 00:00:00 GMT-0300 (hora estÃ¡ndar de Argentina)
 */

export const getDateByDirection = (date, direction, isWeekly, selectedMonth) => {
	const previousMonth = selectedMonth.monthIndex - 1;
	const nextMonth = selectedMonth.monthIndex + 1;
	const directionsObj = {
		prev: isWeekly ? subDays(date, 7) : setMonth(date, previousMonth),
		next: isWeekly ? addDays(date, 7) : setMonth(date, nextMonth)
	};
	return directionsObj[direction] || startOfToday();
};

export const hours = Array(24)
	.fill(0)
	.map((_, index) => index);

export const formatHour = hour => `${hour.toString().padStart(2, '0')}:00`;

/**
 * @function getDayEventUtilVariables
 * @description Returns utility variables related to an event for a specific day
 * @param {Date} day - The specific day for which utility variables are being calculated
 * @param {object} event - The event object containing start and end dates
 * @returns {object} - Object containing utility variables
 * @property {boolean} startToday - Indicates if the event starts on the specified day
 * @property {boolean} endToday - Indicates if the event ends on the specified day
 * @property {string} formattedStartTime - Formatted start time of the event (HH:mm)
 * @property {string} formattedEndTime - Formatted end time of the event (HH:mm)
 * @example
 * const day = DateType;
 * const event = { dateStart: '2024-03-19T10:00:00Z', dateEnd: '2024-03-19T12:00:00Z' };
 * getDayEventUtilVariables(day, event);
 * Result:
 * {
 *    startToday: true,
 *    endToday: true,
 *    formattedStartTime: '10:00',
 *    formattedEndTime: '12:00'
 * }
 */

export const getDayEventUtilVariables = (day, event) => {
	const startToday = isEqual(day, startOfDay(new Date(event.dateStart)));
	const endToday = isEqual(day, startOfDay(new Date(event.dateEnd)));
	const formattedStartTime = format(
		typeof event.dateStart === 'string' ? parseISO(event.dateStart) : event.dateStart,
		'HH:mm'
	);
	const formattedEndTime = format(
		typeof event.dateEnd === 'string' ? parseISO(event.dateEnd) : event.dateEnd,
		'HH:mm'
	);
	return { startToday, endToday, formattedStartTime, formattedEndTime };
};

/**
 * @function groupOverlappingEvents
 * @description order events array to overlapping events
 * @param {Array} events - Array of data
 */
export const groupOverlappingEvents = events => {
	events.sort((a, b) => new Date(a.dateStart) - new Date(b.dateStart));

	const groupedEvents = [];
	let currentGroup = [events[0]];

	for (let i = 1; i < events.length; i++) {
		const currentEvent = events[i];
		const lastEventInGroup = currentGroup[currentGroup.length - 1];

		if (currentEvent.dateStart < lastEventInGroup.dateEnd) {
			currentGroup.push(currentEvent);
		} else {
			groupedEvents.push(currentGroup);
			currentGroup = [currentEvent];
		}
	}

	currentGroup.sort((a, b) => a.startDate - b.startDate);
	groupedEvents.push(currentGroup);

	return groupedEvents;
};

/**
 * @function getMappedEventsThisHour
 * @description Returns mapped events that occur during a specific hour of the day
 * @param {DateType} day - Date of the specific day
 * @param {Array} eventsThisDay - Array of events occurring on the specified day
 * @param {number} dataHour - The hour for which events are being filtered
 * @param {string} type - Type of events being filtered (optional)
 * @returns {Array} - Array of mapped events occurring during the specified hour
 * @example
 * const dayData = new Date('2024-03-19');
 * const eventsThisDay = [{ dateStart: '2024-03-19T10:00:00Z', dateEnd: '2024-03-19T12:00:00Z' }];
 * const dataHour = 10;
 * const type = 'monthly';
 * getMappedEventsThisHour(dayData, eventsThisDay, dataHour, type);
 * Result:
 * [
 *    {
 *      dateStart: '2024-03-19T10:00:00Z',
 *      dateEnd: '2024-03-19T12:00:00Z',
 *      startTime: '10:00',
 *      endTime: '12:00',
 *      type: 'monthly',
 *      hoursAmount: 2,
 *      startToday: true,
 *      extraDays: 0
 *    }
 *  ]
 */

export const getMappedEventsThisHour = (day, eventsThisDay, dataHour, type, onlyMobile) =>
	eventsThisDay
		.filter(event => {
			if (!event) return;
			const startToday = isEqual(day, startOfDay(new Date(event?.dateStart)));
			const startTime = startToday
				? Number(format(parseISO(event?.dateStart), 'HH:mm').slice(0, 2))
				: 0;
			return startTime === dataHour;
		})
		.reduce((mappedEventsAccum, originalEvent, index) => {
			const {
				startToday,
				endToday,
				formattedStartTime,
				formattedEndTime
			} = getDayEventUtilVariables(day, originalEvent);
			const startTime = startToday ? Number(formattedStartTime.slice(0, 2)) : 0;
			const endTime = endToday ? Number(formattedEndTime.slice(0, 2)) || 24 : 24;
			const hoursAmount = endTime - startTime;
			const extraDays =
				differenceInCalendarDays(
					new Date(originalEvent?.dateEnd),
					new Date(originalEvent?.dateStart)
				) -
				(isEqual(startOfDay(new Date(originalEvent?.dateEnd)), new Date(originalEvent?.dateEnd))
					? 1
					: 0);

			const mappedEventsList = [
				...mappedEventsAccum,
				{
					...originalEvent,
					startTime: formattedStartTime,
					endTime: formattedEndTime,
					type,
					hoursAmount,
					startToday,
					extraDays,
					width: onlyMobile ? '40vw' : undefined,
					...(index < 4 && { newWidth: `${100 - index * 10}%` })
				}
			];
			return mappedEventsList;
		}, []);

/**
 * @function getMappedEventsThisWeek
 * @description Returns mapped events that occur during a specific week
 * @param {object} day - Object representing a day of the week
 * @param {Array} eventsThisWeek - Array of events occurring during the week
 * @param {Array} weekDays - Array of objects representing the days of the week
 * @param {string} type - Type of events being filtered (optional)
 * @returns {Array} - Array of mapped events occurring during the specific week
 * @example
 * const day = { date: DateType };
 * const eventsThisWeek = [{ dateStart: '2024-03-18T10:00:00Z', dateEnd: '2024-03-18T12:00:00Z' }];
 * const weekDays = [{ date: new Date('2024-03-18') }, ..., { date: new Date('2024-03-24') }];
 * const type = 'weekly';
 * getMappedEventsThisWeek(day, eventsThisWeek, weekDays, type);
 * Result:
 * [
 *    {
 *      dateStart: '2024-03-18T10:00:00Z',
 *      dateEnd: '2024-03-18T12:00:00Z',
 *      startTime: '10:00',
 *      endTime: '12:00',
 *      type: 'weekly',
 *      hoursAmount: 2,
 *      startToday: true,
 *      eventDuration: 0
 *    }
 * ]
 */

export const getMappedEventsThisWeek = (day, eventsThisWeek, weekDays, type) =>
	eventsThisWeek
		.filter(
			event =>
				isEqual(startOfDay(new Date(event.dateStart)), day.date) ||
				(isBefore(new Date(event.dateStart), day.date) && isMonday(day.date))
		)
		.reduce((mappedEventsAccum, originalEvent) => {
			const { startToday, formattedStartTime, formattedEndTime } = getDayEventUtilVariables(
				day.date,
				originalEvent
			);
			const dateStart = startToday ? new Date(originalEvent.dateStart) : day.date;
			const hoursAmount =
				Number(formattedEndTime.slice(0, 2)) - Number(formattedStartTime.slice(0, 2));
			const dateEnd = isAfter(new Date(originalEvent.dateEnd), endOfDay(weekDays[6].date))
				? endOfDay(weekDays[6].date)
				: new Date(originalEvent.dateEnd);
			const eventDuration =
				differenceInCalendarDays(dateEnd, dateStart) -
				(isEqual(dateEnd, startOfDay(dateEnd)) ? 1 : 0);
			const mappedEventsList = [
				...mappedEventsAccum,
				{
					...originalEvent,
					dateStart,
					startTime: formattedStartTime,
					endTime: formattedEndTime,
					type,
					hoursAmount,
					startToday,
					eventDuration
				}
			];
			return mappedEventsList;
		}, []);

/**
 * @function addFormatSchedule
 * @description Add properties to array's objects
 * @param {Array} array - Array of data
 * @param {DateType} date - Date to get data
 */
export const addFormatSchedule = (array, date) =>
	array.map(item => {
		const { formattedStartTime, formattedEndTime } = getDayEventUtilVariables(date, item);

		return {
			...item,
			startTime: formattedStartTime,
			endTime: formattedEndTime
		};
	});

/**
 * @function filterEventsThisWeek
 * @description Filters events to include only those occurring during the current week
 * @param {Array} events - Array of events to filter
 * @param {Array} weekDays - Array of objects representing the days of the week
 * @returns {Array} - Filtered array containing events occurring during the current week
 * @example
 * const events = [{ dateStart: '2024-03-18T10:00:00Z', dateEnd: '2024-03-18T12:00:00Z' },
 * 				   { dateStart: '2024-03-25T10:00:00Z', dateEnd: '2024-03-25T12:00:00Z' }];
 * const weekDays = [{ date: new Date('2024-03-18') }, ..., { date: new Date('2024-03-24') }];
 * filterEventsThisWeek(events, weekDays);
 * Result:
 * [{ dateStart: '2024-03-18T10:00:00Z', dateEnd: '2024-03-18T12:00:00Z' }]
 */

export const filterEventsThisWeek = (events, weekDays) =>
	events.filter(event => {
		const dateStart = new Date(event.dateStart);
		const dateEnd = new Date(event.dateEnd);
		return (
			((isAfter(dateStart, weekDays[0].date) || isEqual(dateStart, weekDays[0].date)) &&
				(isBefore(dateEnd, endOfDay(weekDays[6].date)) || isEqual(dateEnd, weekDays[6].date))) ||
			(isAfter(dateEnd, weekDays[0].date) &&
				(isBefore(dateStart, weekDays[6].date) || isEqual(startOfDay(dateStart), weekDays[6].date)))
		);
	});

/**
 * @function filterEventsThisDay
 * @description Filters events to include only those occurring during the specified day
 * @param {DateType} day - Date representing the specific day
 * @param {Array} events - Array of events to filter
 * @returns {Array} - Filtered array containing events occurring during the specified day
 * @example
 * const dayData = new Date('2024-03-19');
 * const events = [{ dateStart: '2024-03-19T10:00:00Z', dateEnd: '2024-03-19T12:00:00Z' },
 * 				   { dateStart: '2024-03-20T10:00:00Z', dateEnd: '2024-03-20T12:00:00Z' }];
 * filterEventsThisDay(dayData, events);
 * Result:
 * [{ dateStart: '2024-03-19T10:00:00Z', dateEnd: '2024-03-19T12:00:00Z' }]
 */

export const filterEventsThisDay = (day, events) =>
	events.filter(event => {
		const dateStart = new Date(event.dateStart);
		const dateEnd = new Date(event.dateEnd);
		return (
			((isAfter(dateStart, day) || isEqual(dateStart, day)) &&
				(isBefore(dateEnd, endOfDay(day)) || isEqual(dateEnd, day))) ||
			(isAfter(dateEnd, day) && (isBefore(dateStart, day) || isEqual(startOfDay(dateStart), day)))
		);
	});

/**
 * @function getMappedEventsThisMonth
 * @description Filters events to include only those occurring during the specified month and
 * 				maps them with additional properties
 * @param {DateType} day - Date representing any day within the target month
 * @param {Array} events - Array of events to filter
 * @returns {Array} - Array containing events occurring during the specified month with mapped properties
 * @example
 * const dayData = new Date('2024-03-15');
 * const events = [{ dateStart: '2024-03-10T10:00:00Z', dateEnd: '2024-03-10T12:00:00Z' },
 *                 { dateStart: '2024-03-20T08:00:00Z', dateEnd: '2024-03-20T10:00:00Z' }];
 * getMappedEventsThisMonth(dayData, events);
 * Result:
 * [{ dateStart: '2024-03-10T10:00:00Z',
 * 		dateEnd: '2024-03-10T12:00:00Z',
 * 		startTime: '10:00 AM',
 * 		endTime: '12:00 PM',
 * 		height: '90px',
 * 		width: '80vw',
 * 		type: 'monthly',
 * 		startToday: true }]
 */

export const getMappedEventsThisMonth = (day, events) =>
	events
		.filter(event => {
			const dateStart = new Date(event.dateStart);
			return isSameMonth(dateStart, day);
		})
		.reduce((eventListAccum, originalEvent) => {
			const { formattedStartTime, formattedEndTime } = getDayEventUtilVariables(day, originalEvent);
			const eventList = [
				...eventListAccum,
				{
					...originalEvent,
					startTime: formattedStartTime,
					endTime: formattedEndTime,
					height: '90px',
					width: '80vw',
					type: 'monthly',
					startToday: true
				}
			];
			return eventList;
		}, []);

/**
 * @function formatEventDates
 * @description Format to Text start and end time on an event
 * @param {DateType} start - Date representing event start
 * @param {DateType} end - Date representing event end
 * @returns {string} - Text with short day and time
 * @example
 * formatEventDates('2024-05-28T14:47:53.158Z', '2024-05-29T14:46:17.000Z');
 * Result:
 * Tue 28 - Wed 29
 */
export const formatEventDates = (start, end) => {
	const dateInitial = new Date(start);
	const dateFinal = new Date(end);

	const dayStart = viewsFormat.format(dateInitial, 'EEEE');
	const dayDateStart = viewsFormat.format(dateInitial, 'dd');
	const dayEnd = viewsFormat.format(dateFinal, 'EEEE');
	const dayDateEnd = viewsFormat.format(dateFinal, 'dd');
	const dayStartTranslation = translateHelperString(`views.day.${dayStart}`).slice(0, 3);
	const dayEndTranslation = translateHelperString(`views.day.${dayEnd}`).slice(0, 3);

	return `${dayStartTranslation} ${dayDateStart} - ${dayEndTranslation} ${dayDateEnd}`;
};

/**
 * @function getTimeRange
 * @description Create an object with start and end based on a date and an hour
 * @param {DateType} dateData - Date representing event start
 * @param {number} startHour - Date representing event end
 * @returns {string} - Object with from and to date
 * @example
 * getTimeRange('Sat Jun 08 2024 00:00:00 GMT-0300 (hora estándar de Argentina)', 5);
 * Result:
 * {
    "from": "2024-06-08T05:00:00.000Z",
    "to": "2024-06-08T06:00:00.000Z"
	}
 */
export const getTimeRange = (dateData, startHour, isFull = false) => {
	const baseDate = new Date(dateData);

	const fromDate = new Date(baseDate);
	const toDate = isFull ? endOfDay(baseDate) : new Date(baseDate);

	if (!isFull) {
		fromDate.setHours(startHour);
		toDate.setHours(startHour + 1);
	}

	const fromISO = fromDate.toISOString();
	const toISO = toDate.toISOString();
	return {
		from: fromISO,
		to: toISO
	};
};

/**
 * @function getHiddenItems
 * @description Get hidden items in a group based on a maxQuantity
 * @param {Array} groupedData - Grouped data in an Array of arrays
 * @param {number} maxQuantity - Quantity to subtract for total for each array
 * @returns {number} - Number with hidden items
 */
export const getHiddenItems = (groupedData, maxQuantity = 4) =>
	groupedData.reduce(
		(totalSum, subArray) =>
			totalSum + (subArray.length > maxQuantity ? subArray.length - maxQuantity : null),
		null
	);

/**
 * @function countAvailability
 * @description Format availabilities from an array
 * @param {Array} items - Objects array with a key named availability
 * @returns {object} - Object with availabilities quantity
 */
export const countAvailability = items => {
	const result = items.reduce(
		(acc, item) => {
			if (!item.availability) return acc;
			acc[item.availability] += 1;
			return acc;
		},
		{ available: 0, occupied: 0, notAvailable: 0 }
	);

	if (!result.available && !result.occupied && !result.notAvailable) return null;

	return result;
};

/**
 * @function getNextWeeksByAmount
 * @description Generates an array of following weeks according to the number of weeks starting from the given date
 * @param {DateType} date - A Date object or valid date
 * @param {number} weeksAmount - Amount of weeks to generate
 * @returns {array} - Array of objects containing data returned by the createWeek function
 * @example
 * getNextWeeksByAmount(new Date(2024, 8, 7), 12);
 * Result:
 * [{
    "date": "2024-03-04T03:00:00.000Z",
    "dayNumber": 4,
    "displayedMonth": "4 Mar - 10 Mar 2024",
    "monthIndex": 2,
    "year": 2024,
    "calendarDaysOfWeek": [
			{
				"year": 2024,
				"monthIndex": 2,
				"dayNumber": 4,
				"dayNumberInWeek": 2,
				"date": "2024-03-04T03:00:00.000Z",
				"monthShort": "Mar"
			}, ...],
	"weekDaysNames": [
		{
			"day": "Monday",
			"dayShort": "Mon"
		},...],
	"start": '2024-06-03T03:00:00.000Z',
	"end": '2024-06-09T03:00:00.000Z'
	}]
 */
export const getNextWeeksByAmount = (date, weeksAmount) => {
	if (!isValid(date)) return [];

	return Array.from({ length: weeksAmount }).reduce((weeks, _, index) => {
		const nextMondayDate = nextMonday(addDays(date, index * 7));
		const weekObj = createWeek(nextMondayDate);
		return [...weeks, weekObj];
	}, []);
};

/**
 * @function getRemainingDaysOfWeek
 * @description Create an object with data generated by createDate function and extra data
 * @param {DateType} date - A Date object or valid date
 * @returns {object} - Object containing the remaining days from the next day of the
 * provided date until sunday within the week corresponding to that date
 * @example
 * getRemainingDaysOfWeek(new Date(2024, 8, 7));
 * Result:
 * {
		startDate: { createDate() },
		endDate: { createDate() },
		start: '2024-09-07T03:00:00.000Z',
		date: '2024-09-07T03:00:00.000Z'
		displayedMonth: '02 Sep - 08 Sep 2024'
	};
 */
export const getRemainingDaysOfWeek = date => {
	if (!isValid(date)) return {};
	if (isSunday(date)) return {};

	const nextDayFromCurrentDate = addDays(date, 1);
	const nextSundayFromCurrentDate = getSunday(nextDayFromCurrentDate);

	const startDate = createDate(nextDayFromCurrentDate);
	const endDate = createDate(nextSundayFromCurrentDate);
	const range = parseRange(nextDayFromCurrentDate, nextSundayFromCurrentDate);

	return {
		startDate: { ...startDate },
		endDate: { ...endDate },
		...range,
		displayedMonth: !isSunday(nextDayFromCurrentDate)
			? getDisplayedMonth(nextDayFromCurrentDate, nextSundayFromCurrentDate)
			: `${fillWithZero(startDate?.dayNumber)} ${startDate?.monthShort} ${startDate?.year}`
	};
};

/**
 * @function getWeeks
 * @description Takes the values of getRemainingDaysOfWeek and getNextWeeksByAmount functions and returns all
 * in a new array
 * If the return of the getRemainingDaysOfWeek function is empty is not added to the final array
 * @param {DateType} date - A Date object or valid date
 * @param {number} weeksAmount - Amount of weeks to generate
 * @returns {array} - Array containing the return of the getRemainingDaysOfWeek and getNextWeeksByAmount functions
 * If the return of the getRemainingDaysOfWeek function is empty is not added to the final array
 * @example
 * getWeeks(new Date(2024, 8, 7), 12);
 * Result:
 * [
 *	...getRemainingDaysOfWeek()
 *	...getNextWeeksByAmount()
 * ]
 */
export const getWeeks = (date, weeksAmount) => {
	const remainingDaysObj = getRemainingDaysOfWeek(date);
	const emptyRemainingDaysObj = !Object.keys(remainingDaysObj).length;
	const weeksAmountToGenerate = emptyRemainingDaysObj ? weeksAmount : weeksAmount - 1;
	const nextWeeks = getNextWeeksByAmount(date, weeksAmountToGenerate);

	const weeks = [...nextWeeks];
	if (!emptyRemainingDaysObj) weeks.unshift(remainingDaysObj);

	return weeks;
};
