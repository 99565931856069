import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useDevices, useMobileScrollLock } from 'utils/hooks';
import { getAuthCookieDecoded } from 'utils/auth';
import Shortcuts from 'components/Shortcuts';
import Modal from 'components/ShortcutsModal';
import UnpaidHeader from 'components/UnpaidHeader';
import { StyledText } from 'components/Text/styles';
import Text from 'components/Text';
import UnpaidPopup from 'components/UnpaidPopup';
import { isNumber } from 'lodash';
import { usePopup } from 'hooks';
import StyledPage from './styles';

const Page = ({
	anyModalOpen,
	children,
	isNavbarOpen,
	isBrowseActive,
	shortcuts,
	scrollLock,
	unpaidBillHeader,
	isDev,
	isNew,
	unpaidBillPopup,
	className
}) => {
	const pageRef = useRef();
	const [showShortcutsModal, setShortcutsModal] = useState(false);
	const timeValid = isNumber(unpaidBillPopup);
	const { showPopup, closePopup } = usePopup(unpaidBillPopup);

	const { tabletLg: onlyMobile, onlyDesktop } = useDevices();

	const isScrollLock =
		(onlyMobile && isNavbarOpen) || anyModalOpen || (!onlyMobile && isBrowseActive) || scrollLock;

	useMobileScrollLock(isScrollLock, pageRef);

	return (
		<Shortcuts shortcuts={shortcuts} toggleModal={setShortcutsModal}>
			<StyledPage
				ref={pageRef}
				scrollLock={isScrollLock}
				unpaidheader={unpaidBillHeader}
				isNew={isNew}
				className={className}
			>
				{unpaidBillHeader && <UnpaidHeader TextComponent={onlyDesktop ? StyledText : Text} />}
				{children}
			</StyledPage>
			<Modal showModal={showShortcutsModal} handleCloseModal={() => setShortcutsModal(false)} />
			{!isDev && showPopup && (
				<UnpaidPopup
					time={unpaidBillPopup}
					closePopup={closePopup}
					invalidTime={!timeValid || unpaidBillPopup === 0}
				/>
			)}
		</Shortcuts>
	);
};

Page.propTypes = {
	anyModalOpen: PropTypes.bool,
	children: PropTypes.node,
	isNavbarOpen: PropTypes.bool,
	isBrowseActive: PropTypes.bool,
	shortcuts: PropTypes.arrayOf(PropTypes.shape({})),
	scrollLock: PropTypes.bool,
	unpaidBillHeader: PropTypes.bool,
	isDev: PropTypes.bool,
	isNew: PropTypes.bool,
	unpaidBillPopup: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	className: PropTypes.string
};

Page.defaultProps = {
	shortcuts: [],
	unpaidBillPopup: null
};

const mapStateToProps = ({ browses, page, navbar }) => {
	const currentBrowse = browses[page.schema.name];
	const { unpaidBillHeader, isDev, unpaidBillPopup } = getAuthCookieDecoded();

	return {
		anyModalOpen: page.isModalOpen,
		isNavbarOpen: navbar.isOpen,
		isBrowseActive: !!currentBrowse,
		isDev,
		unpaidBillHeader,
		unpaidBillPopup
	};
};
export default connect(mapStateToProps)(Page);
