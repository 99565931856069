import { set } from 'lodash';
import calculateKeyPath from './calculateKeyPath';

/**
 * Maps the response data to a specific target field, with optional prefixing and uniqueness.
 * @param {Object} params The parameters for the mapping function.
 * @param {Object|Array} params.response The API response to map. Can be an object or an array depending on the API.
 * @param {string} params.targetField The target field key where the data should be set.
 * @param {string} [params.fieldsArrayChildrenPrefix=''] Optional prefix for the field key.
 * @param {boolean} [params.fieldsArrayChildrenUnique=false] Whether the field key should be unique.
 * @returns {Object} The mapped object with the response data set at the target field.
 */
const mapTargetFieldData = ({
	response,
	targetField,
	fieldsArrayChildrenPrefix = '',
	fieldsArrayChildrenUnique = false
}) => {
	if (!targetField) return {};

	const keyPath = calculateKeyPath({
		targetField,
		fieldsArrayChildrenPrefix,
		fieldsArrayChildrenUnique
	});
	return set({}, keyPath, response);
};

export default mapTargetFieldData;
