/**
 * Calculates the key path for the given parameters.
 * @param {string} targetField The target field key.
 * @param {string} [fieldsArrayChildrenPrefix=''] Optional prefix for the field key.
 * @param {boolean} [fieldsArrayChildrenUnique=false] Whether the field key should be unique.
 * @returns {string} The computed key path.
 */
const calculateKeyPath = ({
	targetField,
	fieldsArrayChildrenPrefix = '',
	fieldsArrayChildrenUnique = false
}) =>
	fieldsArrayChildrenPrefix
		? `${fieldsArrayChildrenPrefix}${!fieldsArrayChildrenUnique ? `[${targetField}]` : ''}`
		: targetField;

export default calculateKeyPath;
