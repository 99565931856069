import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { actions } from 'modules/formSection';
import SectionLoader from 'components/SectionLoader';
import FormSection from './FormSection';
import createForm from './createForm';
import FormSectionWrapper from './FormSectionWrapper';
import { dataFormatter } from './utils/dataFormatter';

export const FormSectionContext = React.createContext({
	isEditSection: true,
	contained: false
});

const createFormSectionWrapper = (Component, options = {}, onSuccessCallback) => {
	const { isEditSection = true, contained = false, floatingLabels = false } = options;

	return React.memo(
		({ schema, preloadData, preloadDataIsReady = false, dispatch, isEmpty = false, children }) => {
			const [mount, setMount] = useState(false);

			useEffect(() => {
				dispatch(actions.createFormSection(schema.name, schema));
				setMount(true);

				return () => dispatch(actions.destroyFormSection(schema.name));
			}, []);

			if (!mount) return <SectionLoader schemaData={schema} isEditSection={isEditSection} />;

			return (
				<FormSectionContext.Provider value={{ isEditSection, contained, floatingLabels }}>
					<FormSection
						isEmpty={isEmpty}
						preloadDataIsReady={preloadDataIsReady}
						preloadData={preloadData}
						formSection={schema.name}
						isEditSection={isEditSection}
						schema={schema}
					>
						{children}
						<FormSectionWrapper
							schema={schema}
							formSection={schema.name}
							isEmpty={isEmpty}
							onSuccessCallback={onSuccessCallback}
							dataFormatter={dataFormatter}
						>
							{({ formSection, initialValues, globalDependencies, onSubmit, fieldsGroup }) => (
								<Component
									isEditSection={isEditSection}
									formSection={formSection}
									onSubmit={onSubmit}
									initialValues={{ ...initialValues, globalDependencies }}
									enableReinitialize
									keepDirtyOnReinitialize
									updateUnregisteredFields
									fieldsGroup={fieldsGroup}
									schema={schema}
								/>
							)}
						</FormSectionWrapper>
					</FormSection>
				</FormSectionContext.Provider>
			);
		}
	);
};

export default (form, options = {}, onSuccessCallback = null) => {
	const optionsFormated =
		typeof options === 'boolean' ? { isEditSection: options } : { isEditSection: true, ...options };

	const FormConnected = createForm(form);

	return connect()(createFormSectionWrapper(FormConnected, optionsFormated, onSuccessCallback));
};
